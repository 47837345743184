import * as React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { hot } from "react-hot-loader";
import * as Loadable from "react-loadable";
import { Loading as loading } from "rb-design";
import { IAppProps } from "./IAppTypes";
const Home = Loadable({
  loading,
  loader: () => import(/* webpackChunkName: "Home" */ "../Home")
});
const Manager = Loadable({
  loading,
  loader: () => import(/* webpackChunkName: "Manager" */ "../Manager")
});
const Information = Loadable({
  loading,
  loader: () => import(/* webpackChunkName: "Information" */ "../Information")
});

class App extends React.Component<IAppProps> {
  public render() {
    return (
      <Router>
        <Switch>
          <Route path="/information/:id?" exact={true} component={Information} />
          <Route path="/manager" exact={true} component={Manager} />
          <Route component={Home} />
        </Switch>
      </Router>
    );
  }
}

export default hot(module)(App);
