import * as React from "react";
import { render } from "react-dom";
import Axios from "axios";

// AppContainer is a necessary wrapper component for HMR
// import { AppContainer } from 'react-hot-loader'
// import 'lib-flexible';
// import "antd/dist/antd.min.css";
import "antd-mobile/dist/antd-mobile.less";
import "./../stylesheets/main.less";

/*
  Main App Container
 */
import App from "./containers/App/App";
import { ConsoleHelper, ServiceHelper } from "rb-utility";
const init = async () => {
  try {
    baseConfig = (await Axios.get<BaseConfig>("/config.json")).data;
    ConsoleHelper.development = baseConfig.development;
    ServiceHelper.development = baseConfig.httpDevelopment;
  } catch (error) {
    ConsoleHelper.error(error);
    baseConfig = {
      host: "",
      development: true,
      httpDevelopment: true
    };
  }
  render(<App />, document.getElementById("reactContainer"));
};
init();
